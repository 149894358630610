.app-page-header {
    /* flex: 0 0 50px; */
    min-height: 50px
}

.app-modal-page-header {
    /* flex: 0 0 50px; */
    min-height: 20px
}

.app-page-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 0;
    color: #23282c;
    background: white;
    border-bottom: solid 1px rgba(0,0,0,.05);
}

.app-modal-page-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 0;
    color: #23282c;
    background: white;
    /* border-bottom: solid 1px rgba(0,0,0,.05); */
}

.app-modal-page-header .dropdown-menu {
    min-width: 6rem !important;
    margin-left: 30px !important;
}

.app-page-header .dropdown-item.disabled, .app-page-header .dropdown-item:disabled {
   
    background-color: #dddddd;
}

.page-title {

}

.page-header-dropmenu{
 min-width: 300px;
}

.sync-hide {
    display: none !important;
}

.sync-visible {
    display: block;
}

.modal-form-title
{
width: 100%;
font-weight: 500;
font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.modal-menu-icon
{
    font-size: 16px;
    color: black;
}

.menu-icon
{
    font-size: 26px;
    color: black;
}

.menu-icon-name-left {
    font-size: 14px;
}

.menu-icon-name-right {
    font-size: 14px;
    vertical-align: top;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.react-select__control{
width: 150px;
}

.group-badge
{
    font-size: 14px;
}

.group-icon
{
    height: 35px;
    margin: 0 10px;
    font-size: 18px;
}

.react-bootstrap-table table {
    table-layout: auto;
  }

.react-bootstrap-table
{
    background-color: white;
}

.soulwin-table-header {
    
    line-height: normal;
    font-size: 12px;
    font-weight: 500;
  
}

.soulwin-table-th
{
    border-bottom: none !important;
    max-width: 48px;
}

.table thead th {
    /* vertical-align: bottom; */
    border-bottom: none !important;
    border-top: none !important;
    /* border-bottom: 2px solid #c8ced3; */
}

.soulwin-table-row {
    /* align-content: stretch;
    width: 100%;
    box-sizing: border-box; */
    font-size: 13px;
    cursor: pointer;
    /* color: rgba(0,0,0,0.87);
    background-color: #FFFFFF;
    min-height: 48px; */
}

.soulwin-table-row:hover {
    background-color: #f2f2f2;;
  }

.soulwin-group-image > .img-avatar, .soulwin-group-image > img, .soulwin-group-icon {
    height: 24px;
    margin: 0 10px;
}

.back-btn:hover {
    text-decoration: none;
}



.group-caption {
    position: relative;
    box-sizing: border-box;
    overflow: visible;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    font-size: 22px;
    color: rgba(0,0,0,0.87);
    background-color: #FFFFFF;
    min-height: 56px;
    padding-left: 16px;
    padding-right: 8px;
}


.group-dashboard-title-box {

}

.list-box {
    height: 100%;
    min-width: 1024px;
    min-height: calc(100vh - 155px);
  overflow-x: hidden;
  /* margin: 2px; */
  background-color: white;
  border-top: none;
}

.bucket-cell-box {

    /* padding: 5px; */
    padding-top: 15px;
}

.bucket-box {
    /* margin: 5px; */
    /* border: 2px solid red; */
    padding: 5px;
    height: 100%;
    background-color: white;
    /* border-style: dotted; */
}

.bucket-box-header {
display: flex;
}

.bucket-box-header .nav-link {
   display: initial;
   padding: 0.5rem 1rem;
}


.harvestlocation-cell-box {

    /* padding: 5px; */
    padding-top: 15px;
}

.harvestlocation-box {
    /* margin: 5px; */
    /* border: 2px solid red; */
    padding: 5px;
    height: 100%;
    background-color: white;
    /* border-style: dotted; */
}

.harvestlocation-box-header {
display: flex;
}

.harvestlocation-box-header .nav-link {
   display: initial;
   padding: 0.5rem 1rem;
}

.map-controlbox {
    /* position: absolute; */
    top: 40px;
    /* left: 50%; */
    /* margin-left: -77.5px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
    margin-right: 5px;
}

.map-inputgroup {
    width: 400px;
    }
    

.map-controltest {
    /* position: absolute;  */
    min-height: 70px;
}


@media only screen and (max-width: 1024px) {
    .list-box {
        min-width: 320px;
      margin: 0px !important;
    }
}

@media only screen and (max-width: 768px) {
    /* For mobile: */

    /* .map-controlbox {
        position: absolute;
        float: right;
    } */

    .map-inputgroup {
        width: 300px;
        }
    .soulwin-group-name {
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .group-dashboard-title-box {
        max-width: 85%;
        
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .group-dashboard-title{
        font-size: 14px !important;
    }

    .bucket-box-header {
        display: block;
        }

    .bucket-box-header .nav-link {
        display: initial;
        padding: 0rem 0rem;
     }

     .bucket-box-header .card {
        border-radius: 0rem;
        }
        .harvestlocation-box-header {
            display: block;
            }
    
        .harvestlocation-box-header .nav-link {
            display: initial;
            padding: 0rem 0rem;
         }
    
         .harvestlocation-box-header .card {
            border-radius: 0rem;
            }

     .admin-row-menu i {
        font-size: 20px;
       
     }

     .admin-row-menu i span {
        font-size: 16px;
        vertical-align: top;
       
     }
  }

  .group-dashboard-title{
    font-size: 20px;
    font-weight: 500;
}

.toast-container-alert
{
    min-height: 37px !important;
    width: 220px !important;
}

.map-box {
    height: 100%;
    min-width: 320px;
    min-height: calc(100vh - 155px);
  overflow-x: hidden;
  margin: 2px;
  background-color: white;
  border-top: none;
}


.btn-circle.btn-shadow
{
    /* box-shadow: rgba(0, 0, 0, 0.3) 0px 2px  */
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
}

.btn-white {
    background-color: white;
    border-color: white; 
}



.btn-white:disabled,
.btn-white[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.btn-white.disabled:hover{
    color:red;
}

.circle{
    color: #fff;
    text-align: center;
    background: #000;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: 50px;
}

.circle.circle-sm {
    width: 30px !important;
    height: 30px !important;
    border-radius: 50% !important;
    line-height: 30px !important;
   
  }

  .circle.circle-md {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: 50px;
    font-size: 30px;
  }

  .circle.circle-xl {
    width: 70px !important;
    height: 70px !important;
    border-radius: 50% !important;
    line-height: 70px !important;
    font-size: 42px;
  }

.btn-circle.btn-sm { 
    width: 30px; 
    height: 30px; 
    padding: 6px 0px; 
    border-radius: 15px; 
    font-size: 8px; 
    text-align: center; 
    z-index: 9999;
} 
.btn-circle.btn-md { 
    width: 50px; 
    height: 50px; 
    padding: 7px 10px; 
    border-radius: 25px; 
    font-size: 10px; 
    text-align: center; 
    z-index: 9999;
} 
 
.btn-circle.btn-xl { 
    width: 70px; 
    height: 70px; 
    padding: 10px 16px; 
    border-radius: 35px; 
    font-size: 12px; 
    text-align: center; 
    z-index: 9999;
} 

.sync-circle {
    width: 20%;
    border-radius: 50%;
    background: #bcd6ff; 
  }
  /* Credits - https://spin.atomicobject.com/2015/07/14/css-responsive-square/ */
  .sync-circle:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  .circle-text {
    float: left;
    /*width: 50%;*/
    padding: 5px;
}

    .circle-text:after {
        content: "";
        display: block;
        width: 25px;
        height: 0;
        padding-bottom: 25px;
        background: #4679BD;
        -moz-border-radius: 5%;
        -webkit-border-radius: 5%;
        border-radius: 50%;
    }

    .circle-text div {
        float: left;
        width: 25px;
        padding-top: 12px;
        line-height: 1em;
        margin-top: -0.5em;
        text-align: center;
        color: white;
    }

.circle-text2 {
    float: left;
    /*width: 50%;*/
    padding: 5px;
}

    .circle-text2:after {
        content: "";
        display: block;
        width: 25px;
        height: 0;
        padding-bottom: 25px;
        background: #b34700;
        -moz-border-radius: 5%;
        -webkit-border-radius: 5%;
        border-radius: 50%;
    }

    .circle-text2 div {
        float: left;
        width: 25px;
        padding-top: 12px;
        line-height: 1em;
        margin-top: -0.5em;
        text-align: center;
        color: white;
    }

/* .circle {
    width: 500px;
    height: 500px;
    border-radius: 50%;
    font-size: 50px;
    color: #fff;
    line-height: 500px;
    text-align: center;
    vertical-align: middle;
    display: table-cell;
    background: #000;
} */


/*
*
* ==========================================
* CUSTOM UTIL CLASSES
* ==========================================
*
*/

hr.dashed {
    border-top: 2px dashed #999;
}

hr.dotted {
    border-top: 2px dotted #999;
}

hr.solid {
    border-top: 2px solid #999;
}


hr.hr-text {
  position: relative;
    border: none;
    height: 1px;
    background: #999;
}

hr.hr-text::before {
    content: attr(data-content);
    display: inline-block;
    background: #fff;
    font-weight: bold;
    font-size: 0.85rem;
    color: #999;
    border-radius: 30rem;
    padding: 0.2rem 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/*
*
* ==========================================
* Google AutoComplet Modal Css
* ==========================================
*
*/

.pac-container {
    z-index: 1100 !important;
}

.append-icon {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}


/*
*
* ==========================================
* React-fluid-table css
* ==========================================
*
*/

.sticky-header {
    background-color: white;
}

.prospect-search .form-control:focus {
    /* color: #5c6873; */
    /* background-color: #fff; */
    /* border-color: #8ad4ee; */
    outline: 0;
    box-shadow: none !important;
}

.prospect-list-box .nav-link {
    display: block !important;
    padding: 0 !important;
}



.prospect-list-box .dropdown-menu {
    min-width: 6rem !important;
    margin-left: 30px !important;
}
.prospect-list-box .dropdown-menu.show {
    position: fixed !important;
}

.prospect-list-box .dropdown-item {
    /* position: fixed !important; */
    padding: 0.25rem 0.75rem !important;
    border-bottom: 0 !important;
}

.prospect-list-box .dropdown-item:hover, .list-box .dropdown-item:focus {
    color: #181b1e;
    text-decoration: none;
    background-color: white;
}

.prospect-details-modal-header {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-box;
    display: box;
    width: 100%
}

.prospect-details-modal-header .modal-title {
    width: 100%
}

.modal-title {
    width: 100%;
}

.prospect-details-heading {
    font-size: 1.125rem;
    letter-spacing: 1.5px;
    line-height: 1.5rem;
}

.fa-1-5x {
    font-size: 1.5em;
}

.react-fluid-table-row {
    background-color: white;
    cursor: pointer;
}

.react-fluid-table-row:hover {
    background-color: #f2f2f2;
  }

.fluid-table-row {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 768px) {

    .fluid-table-row {
        display: block;
    }
}

#editProspectDetails .modal-body {
    padding: 0rem !important; 
}

#editProspectDetails .modal-header {
    border-bottom: none;
}

#editProspectDetails .card {
    border-bottom: none;
    border-radius: 0em;
}

#editProspectDetails .card-footer {
    border-top: none;
    background-color: white;
}

#displayProspectDetails .modal-body {
    padding: 0.7rem !important; 
    padding-top: 0rem !important; 
}

.link-no-line:hover{
    text-decoration: none;
}

.user-profile label {

    letter-spacing: .07272727em;
    font-family: Roboto,Arial,sans-serif;
    font-size: .6875rem;
    font-weight: 500;
    line-height: 1rem;
    text-transform: uppercase;
    hyphens: auto;
    word-break: break-word;
    word-wrap: break-word;
    color: #5f6368;
    /* margin: 0; */
    padding: 0;
    width: 100%;
}